var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"50-VuNpuGJUHQrYh0IKyT"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const CHANNEL = process.env.CHANNEL || process.env.NEXT_PUBLIC_CHANNEL;

Sentry.init({
  dsn: SENTRY_DSN || "https://0467a5b196ed46deb0a59eec4fabf255@glitchtip.metafora.dev/4",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // tunnel: "/api/tunnel/errors",
  debug: true,
  autoSessionTracking: false, // not working
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: CHANNEL || "unset",
});
